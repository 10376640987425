@font-face {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/Inter-Light.ttf") format("truetype");
}
@font-face {
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/Inter-Bold.ttf") format("truetype");
}
