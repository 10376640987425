.table-paging {
  display: flex;
  justify-content: center;
}
.paging {
  list-style-type: none;
  display: flex;
  padding: 0;
  gap: 8px;

  .paging-prev {
    background-image: url("/assets/img/arrow-left.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  .paging-next {
    background-image: url("/assets/img/arrow-right.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  .paging-active {
    color: #ffffff;
  }

  .break {
    cursor: inherit;
  }

  li {
    display: inline-block;
    min-width: 30px;
    height: 24px;
    cursor: pointer;
    color: #292d32;
    color: #84858f;
    font-family: "Inter-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    //border: 1px solid red;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 990px) {
  .table-paging {
    display: none;
  }
}
