.form-label {
    color: #85868F;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 9px;
    display: block;
    padding-left: 17px;
}
.form-control {
    width: calc(100% - 76px);
    height: 52.4px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid #2A2A2D;
    background: #1D1D1F;
    color: #85868F;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0 38px;
    display: block;
    transition: all 0.3s ease;
    margin-bottom: 12px;
}
.form-control:hover {
    border-color: #3f3f44;
}

input.form-control::placeholder {
    transition: all 0.3s ease;
}
input.form-control:focus::placeholder {
    color: #a3a4b0;
}
input.form-control:focus {
    border-color: #3f3f44;
    outline: 1px solid #3f3f44;
    -webkit-box-shadow: 0 0 var(--shadow-size) 2px rgba(21, 103, 226, var(--shadow-opacity));
    -moz-box-shadow: 0 0 var(--shadow-size) 2px rgba(21, 103, 226, var(--shadow-opacity));
    box-shadow: 0 0 var(--shadow-size) 2px rgba(21, 103, 226, var(--shadow-opacity));
}
.input-group {
    position: relative;
}
.input-group .input-icon {
    position: absolute;
    bottom: 10px;
    right: 18px;
    cursor: pointer;
}

.btn {
    height: 54px;
    flex-shrink: 0;
    border-style: none;
    border-radius: 2px;
    background: #1567E2;
    color: #FFF;
    font-family: Inter, serif;
    font-family: "Inter-Medium", Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease;
}
.btn:hover {
    background: #3D88F8;
}
.btn:active {
    background: #3775d5;
}
.btn.disabled {
    pointer-events: inherit;
    cursor: wait;
    opacity: 0.7;
}

.btn-gray {
    background: #2A2A2D;
}
.btn-gray:hover {
    background: #303034;
}

.btn-red {
    background: #DA5D5D;
}
.btn-red:hover {
    background: #f17979;
}

.btn-blue {
    color: #FFFFFF;
    background-color: #1466e2;
    border-radius: 2px;
}
.btn-blue:hover {
    background: #3c87f7;
}

.btn-darkgray {
    color: #85868F;
    background: #1C1C1F;
    border: 1px solid #29292c;
    border-radius: 2px;
}
.btn-darkgray:hover {
    color: #85868F;
    background: #26262a;
    background-color: #313135;
}

.btn-darkgray1 {
    color: #85868F;
    background: #1c1c1e;
    border: 1px solid #29292c;
    border-radius: 2px;
}
.btn-darkgray1:hover {
    color: #85868F;
    background: #26262a;
    background-color: #313135;
}

.btn.btn-sm {
    height: 30px !important;
}

.select {
    height: 54px;
    /*width: max-content;*/
}
.disabled {
    color: #999999;
    pointer-events: none;
}
