@font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/Inter-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Inter-Bold";
    src: url("../fonts/Inter-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Inter-Light";
    src: url("../fonts/Inter-Light.otf") format("opentype");
}

.fs-5 {
    font-size: 1.25rem !important;
}
.fs-6 {
    font-size: 1.0rem !important;
}