.d-flex {
    display: flex !important;
}
.flex-fill {
    flex: 1 1 auto !important;
}
.flex-grow-0 {
    flex-grow: 1;
}
.flex-grow-1 {
    flex-grow: 1;
}
.flex-shrink-0 {
    flex-shrink: 1;
}
.flex-shrink-1 {
    flex-shrink: 1;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
