/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Roboto:wght@100;300;400&display=swap');*/
@import 'bootstrap.css';
@import 'flex.css';
@import 'forms.css';
@import 'styles.css';
@import 'fonts.css';

html, body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  font-family: 'Inter', sans-serif;
}
body {
  background: #141414;
}
