@import url(bootstrap-utils.css);
:root {
    --margin-unit: 8px;
}

.d-block {
    display: block !important;
}
.d-inline {
    display: inline !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-none {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.position-relative {
    position: relative;
}

.mb-1 {
    margin-bottom: var(--margin-unit);
}
.mb-2 {
    margin-bottom: calc(var(--margin-unit) * 2);
}
.mb-3 {
    margin-bottom: calc(var(--margin-unit) * 3);
}

.me-1 {
    margin-right: var(--margin-unit);
}
.me-2 {
    margin-right: calc(var(--margin-unit) * 2);
}
.me-3 {
    margin-right: calc(var(--margin-unit) * 3);
}

.ms-1 {
    margin-left: var(--margin-unit);
}
.ms-2 {
    margin-left: calc(var(--margin-unit) * 2);
}
.ms-3 {
    margin-right: calc(var(--margin-unit) * 3);
}

.mt-1 {
    margin-top: var(--margin-unit);
}
.mt-2 {
    margin-top: calc(var(--margin-unit) * 2);
}

.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 100%;
}

.pe-1 {
    padding-right: var(--margin-unit);
}
.pe-2 {
    padding-right: calc(var(--margin-unit) * 2);
}
.pe-3 {
    padding-right: calc(var(--margin-unit) * 3);
}


.ps-1 {
    padding-left: var(--margin-unit) !important;
}
.ps-2 {
    padding-left: calc(var(--margin-unit) * 2) !important;
}
.ps-3 {
    padding-left: calc(var(--margin-unit) * 3) !important;
}
.ps-4 {
    padding-left: calc(var(--margin-unit) * 4) !important;
}
.ps-5 {
    padding-left: calc(var(--margin-unit) * 5) !important;
}
.ps-6 {
    padding-left: calc(var(--margin-unit) * 6) !important;
}

.pt-1 {
    padding-top: var(--margin-unit);
}
.pt-2 {
    padding-top: calc(var(--margin-unit) * 2);
}
.pt-3 {
    padding-top: calc(var(--margin-unit) * 3);
}

.px-1 {
    padding: 0 var(--margin-unit) 0 var(--margin-unit);
}
.px-2 {
    padding: 0 calc(var(--margin-unit) * 2) 0 calc(var(--margin-unit) * 2);
}
.px-3 {
    padding: 0 calc(var(--margin-unit) * 3) 0 calc(var(--margin-unit) * 3);
}

.text-center {
    text-align: center !important;
}
.text-start {
    text-align: left !important;
}
.text-end {
    text-align: right !important;
}

.w-auto {
    width: auto !important;
}
.w-100 {
    width: 100% !important;
}
