:root {
    --max-content-width: 1342px;
    --content-top: 160px;
    --content-padding: 53px;
    --footer-height: 102px;

    --color-link: #7c7c7c;
    --color-label: #84858f;
}
.rodal {
    display: flex;
    position: fixed;
    z-index: 10000 !important;
}
.rodal-dialog {
    position: relative;
    height: auto !important;
}

.red, .red a {
    color: #ff7a7a !important;
}
.green, .green a {
    color: #528651 !important;
}

.content {
    min-height: calc(100% - var(--content-top) - var(--footer-height) - 20px);
    padding: 7px 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
.content section {
    width: calc(100% - var(--content-padding) * 2);
    max-width: var(--max-content-width);
}

.color-label {
    color: var(--color-label);
}

a:visited {
    color: #84858f;
    transition: all 0.3s ease;
}

.max-content {
    width: max-content !important;
}

.rodal-dialog {
    background: linear-gradient(180deg, rgb(27.73, 27.77, 28.69) 0%, rgb(23.32, 23.36, 24.44) 100%);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #2b2b2b;
    border-radius: 13px;
    width: 600px !important;
}

.fancybox__container {
    z-index: 6000 !important;
}

.size-0 {
    width: 0 !important;
    height: 0 !important;
}

@media (max-width: 990px) {
    .content section {
        width: calc(100% - 20px * 2);
        max-width: inherit;
    }
    .rodal-dialog {
        width: calc(100% - 36px) !important;
    }
}


.error .control-label {
    color: #e74c3c !important;
}